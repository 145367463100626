import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TestService from "../../Services/TestService";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GeneralFunctions from "../../libs/GeneralFunctions";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { es } from "date-fns/locale";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function GameResets(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [gameResets, setGameResets] = React.useState([]);

  useEffect(() => {
    if(open == true) {
      setLoading(true);

      TestService.getHistoryReset(props.gameType, props.game_id)
        .then((response) => {
          setGameResets(response.data.content);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });      
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={"Historial de Reseteos"}
      >
        <CalendarTodayIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={handleClickOpen}
        />
      </Tooltip>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Historial de Reseteos
        </DialogTitle>

        <DialogContent dividers>
          {loading ? (
            <div className="div-spinner">
              <Grid container spacing={1}>
                <SpinnerCircular size={90} />
              </Grid>
            </div>
          ) : (
            <>
              {gameResets.length == 0 ? (
                <h4 className="text-center">
                  El historial esta vacio.
                </h4>
              ) : (
                <TableContainer component={Paper}>
                  <Table  aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="rowTable" align="left">
                          Usuario
                        </TableCell>
                        <TableCell className="rowTable" align="left">
                          Nombre
                        </TableCell>
                        <TableCell className="rowTable" align="left">
                          Apellido
                        </TableCell>
                        <TableCell className="rowTable" align="left">
                          Fecha
                        </TableCell>
                        <TableCell className="rowTable" align="left">
                          Usuario Jugador
                        </TableCell>
                        <TableCell className="rowTable" align="left">
                          Nombre Jugador
                        </TableCell>
                        <TableCell className="rowTable" align="left">
                          Apellido Jugador
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {gameResets
                        .map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                            >
                              {row.user.username}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                            >
                              {row.user.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                            >
                              {row.user.lastName}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                            >
                              {GeneralFunctions.formatNewDate(
                                row.date,
                                es
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                            >
                              {row.user_player != undefined ? row.user_player.username : '---'}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                            >
                              {row.user_player != undefined ? row.user_player.name : '---'}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="pointer testTableCell"
                            >
                              {row.user_player != undefined ? row.user_player.lastName : '---'}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
           </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
