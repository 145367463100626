import React, { useContext, useEffect, Suspense } from "react";
import "./i18n";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { setSesion } from "./components/app/public/auth/AuthenticationActions";
import AuthComponent from "./components/app/auth2/AuthComponent";
import Landing from "./components/app/public/landing/Landing";
import CustomLanding from "./components/app/public/landing/CustomLanding";

import { IntlProvider } from "react-intl";
import { LocaleContext } from "./LocaleContext";
import locales from "./locales";
import EmailListRecoverPassword from "./components/app/public/recover-password/EmailListRecoverPassword";
import ChangePassword from "./components/app/public/recover-password/ChangePassword";
import axiosconf from "./axios";
import { useAuth } from "./components/app/public/auth/useAuth";
import { USER_TYPES } from "./components/app/public/auth/AuthenticationActions";
import AdminRoutes from "./components/app/admin/Routes";
import MotivarnosRoutes from "./components/app/jugadores/Routes";
import Error500 from "./Pages/Errors/Error500";
import ShowLoginLanding from "./components/app/public/auth/ShowLoginLanding";
import "./GlobalStyles/styles.scss";


const ConfLang = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    let sesion;
    try {
      sesion = JSON.parse(localStorage.getItem("sesion"));
    } catch (error) {
      sesion = null;
    }
    if (sesion) {
      try {
        if (sesion.user.lang) {
          i18n.changeLanguage(sesion.user.lang);
        } else {
          i18n.changeLanguage("es");
        }
      } catch {
        i18n.changeLanguage("es");
      }
    } else {
      let lanCode = navigator.language.split("-")[0];
      i18n.changeLanguage(lanCode);
    }
  }, []);
  return "";
};

const App = () => {
  const [locale] = useContext(LocaleContext);
  const dispatch = useDispatch();

  axiosconf();

  useEffect(() => {
    let sesion;
    try {
      sesion = JSON.parse(localStorage.getItem("sesion"));
    } catch (error) {
      sesion = null;
    }
    dispatch(setSesion(sesion));
  }, []);

  const { session, hasToken, loggedAs } = useAuth();
  const showAdmin = loggedAs === USER_TYPES.admin;
  const showApp = loggedAs === USER_TYPES.user;
  return (
    <Suspense fallback={null}>
      <IntlProvider locale={locale} messages={locales[locale]}>
        <Router basename="/">
          <Route exact path="/signin" component={AuthComponent} />
          {<ConfLang />}
          {hasToken && session && showAdmin && <AdminRoutes />}
          {hasToken && session && showApp && <MotivarnosRoutes />}
          {!hasToken && !session ? (
            <>
              <Switch>
                <Route
                  exact
                  path="/recoverPassword"
                  component={EmailListRecoverPassword}
                />
                <Route
                  exact
                  path="/resetpassword/:hash/company/:company/username/:username"
                  component={ChangePassword}
                />
                <Route exact path="/admin">
                  <Redirect to="/"></Redirect>
                </Route>
                <Route exact path="/panel">
                  <Redirect to="/"></Redirect>
                </Route>
                <Route path="/showroom" component={ShowLoginLanding} />
                <Route path="/demo" component={ShowLoginLanding} />
                <Route path="/gamifica" component={ShowLoginLanding} />

                <Route path="/peyastars" component={CustomLanding} />
                
                <Route path="/serverdown" component={Error500} />

                <Route path="/" component={Landing} />
              </Switch>
            </>
          ) : (
            ""
          )}
        </Router>
      </IntlProvider>
    </Suspense>
  );
};

export default App;
