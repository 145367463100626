import axios from "axios";
import environment from "../../../libs/environment";

export default {
  getMission: (id, voters = false) =>  
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/missionuser?listPublic=true&listComments=true&id=${id}${voters ? '&voters=true' : ''}`,
    }),

  getVoters: (mission_id) =>  
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/vote?missionUser.id=${mission_id}`,
    }),
  
  getMissions: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/missionuser`,
    }),

  getMissionsList: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/missionuser?listPublic=true&listComments=true`,
    }),

  getMissionsListPublic: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/missionuser?listPublic=true`,
    }),

  getMissionsAchieved: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/missionuser/public`,
    }),

  voteMission: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/missionuser/vote`,
      data: data,
    }),

  acceptMission: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/missionuser/accept`,
      data: body,
    }),
  rejectMission: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/missionuser/reject`,
      data: body,
    }),
  achieveMission: (body) =>
    axios({
      method: "PUT",
      url: `${environment.motivarnosBackend}/missionuser/complete`,
      data: body,
    }),

  sendMissionComment: (body) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/missionuser/comment`,
      data: body
    }),
};
