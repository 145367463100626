import axios from "axios";
import environment from "../../../libs/environment";

export default {

  findAll: async (query) =>
    await axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/game/own`,
    }),
  findForUser: async (query) =>
    await axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/game/foruser`,
    }),
  findById: async (id) =>
    await axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/lettersoup/${id}`,
    }),
    play: async (data) =>
      await axios({
        method: "POST",
        url: `${environment.motivarnosBackend}/lettersoup/play`,
        data: data,
      }),
  setWin: async (data) =>
    await axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/lettersoup/win`,
      data: data,
    }),
    setLose: async (data) =>
    await axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/lettersoup/lose`,
      data: data,
    }),
  getScore: async (id) =>
    await axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/gameuserscore/${id}`,
    }),
  getUser: async (id) =>
    await axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/${id}`,
    }),

  setUserScore: async (data) => {
    await axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/gameuserscore`,
      data: data,
    })
  },
  getUserReport: async (data) =>
    await axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/game/userreport`,
      data: data,
    }),
};
