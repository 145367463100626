import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import environment from "../../../../../libs/environment";
import trivia_logo from "../../../../../assets/img/trivia-logo.png";
import novedad_logo from "../../../../../assets/img/novedad-logo.png";
import mision_logo from "../../../../../assets/img/mision-logo.png";

import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ShowImageDialog({
  openModal,
  openModalFunction,
  imageModal,
  videoModal,
  videoFromYoutube,
  titleModal
}) {
  const [open, setOpen] = useState(openModal);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    openModalFunction(false);
  };

  useEffect(() => {
    if(imageModal != 'trivia_logo' && imageModal != 'novedad_logo' && imageModal != 'mision_logo' && videoModal == false) {
      const img = new Image();
      img.src = environment.motivarnosBackend + imageModal;
      setWidth(img.naturalWidth);
      setHeight(img.naturalHeight);
    }
  },[])

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {titleModal != false && (
          <DialogTitle>
            {titleModal}
          </DialogTitle>
        )}

        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12} className="text-center">
              {imageModal == 'trivia_logo' && videoModal == false &&
                <img
                  className="img-new-modal"
                  src={trivia_logo}
                  alt="New"
                />
              }
              {imageModal == 'novedad_logo' && videoModal == false &&
                <img
                  className="img-new-modal"
                  src={novedad_logo}
                  alt="New"
                />
              }
              {imageModal == 'mision_logo' && videoModal == false &&
                <img
                  className="img-new-modal"
                  src={mision_logo}
                  alt="New"
                />
              }
              {imageModal != 'trivia_logo' && imageModal != 'novedad_logo' && imageModal != 'mision_logo' &&  videoModal == false &&
                <img
                  className="img-new-modal"
                  src={environment.motivarnosBackend + imageModal}
                  style={{width: width >= height ? "100%" : "none" }}
                  alt="New"
                />
              }
              {videoModal == true &&
              <>
                {videoFromYoutube ? (
                  <iframe width="420" height="315" src={imageModal}></iframe>
                ) : (
                  <video width="320" height="240" controls>
                    <source src={imageModal} type="video/mp4" />
                  </video>
                )}
               </>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("menu.trivia-panel-dialog-add-test-button-close", "Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
